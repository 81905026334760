// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";
import React from "react";

const Authors = ({ title, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card overflowX={{ sm: "scroll", xl: "scroll" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          <center>
          {title}
          </center>
        
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant='striped' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' fontSize={15} key={idx} ps={idx === 0 ? "0px" : null}>
                    {/* <center> */}
                    {caption}
               {/* </center>      */}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <TablesTableRow
                  // key={`${row.email}-${row.name}`}
                  name={row.particulars}
                  // logo={row.logo}
                  // email={row.email}
                  // subdomain={row.subdomain}
                  // deductamount={row.deductamount}
                  reqamount={row.grossamount}
                  agreeamount={row.contractVal}
                  // category= {row.category}
                  itemScore= {row.itemScore}
                  category= {row.category}
                  diffamount={row.diffvalue}
                  discount ={row.discount}
                  remarks = {row.remarks}
                  newMatch = {row.newMatch}
                  final_price={row.final_price}
                  // date={row.date}
                  // admiamount={row.admiamount}
                  // reason={row.reason}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Authors;

import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function TablesTableRow(props) {
  const { logo, name, email, subdomain, reqamount, agreeamount,itemScore, deductamount, admiamount, reason,category,diffamount,discount,remarks,newMatch,final_price } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td pb=".5rem">
        {/* <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex> */}
         <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {name}
          </Text>
      </Td>

      <Td>
        {/* <Flex direction="column"> */}
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {reqamount}
          </Text>
        {/* </Flex> */}
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
        {remarks ? agreeamount : "-"}
        </Text>
      </Td>
      <Td>
        {/* <Flex direction="column"> */}
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" textColor={((itemScore > 70 ) & (itemScore != null)) ? "green" : "red"}>
            {itemScore}
          </Text>
        {/* </Flex> */}
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {category}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" textColor={(diffamount < 0) ? "red" : "green"}>
        {remarks ? diffamount : "-"}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {discount}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
        {remarks ? "-" : "Check Manually"}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {remarks ? newMatch : "-"}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {final_price}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {deductamount}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {deductamount}
        </Text>
      </Td>
      {/* <Td>
        <Button p="0px" bg="transparent" variant="no-hover">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td> */}
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {admiamount}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {reason}
        </Text>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;

// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    Button,
    Progress,
    Spinner
  } from "@chakra-ui/react";
  // assets
  // Custom icons
  import React from "react";
  import { Dropzone, FileMosaic } from "@dropzone-ui/react";
  
  export default function Files() {
    const [loading, setLoading] = React.useState(false);
    const [socket, setSocket] = React.useState(null);
    const [message, setMessage] = React.useState('');

 
    React.useEffect(() => {
      const ws = new WebSocket("ws://localhost:8000/ws");
      ws.onopen = () => console.log("WebSocket connected");
      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.total_files !== undefined && data.uploaded_files !== undefined) {
          setProgressData(prevProgressData => ({
            ...prevProgressData,
            ...data,
            current_file_progress:
              data.current_file_progress !== undefined
                ? data.current_file_progress
                : null,
            uploaded_files: data.total_files === data.uploaded_files ? 0 : data.uploaded_files
          }));
          setLoading(false);
        } else if (data.filename !== undefined && data.status !== undefined) {
          setStatus(data);
          setLoading(true);
        }
        else if (data.total_files !== undefined && data.processed_files !== undefined) {
          setStatusa(data);
          setLoading(false);
        }
        console.log(loading)
        
    
      };
      ws.onclose = () => console.log("WebSocket disconnected");
      
      return () => {
        ws.close();
      };
    }, []);

    const [progressData, setProgressData] = React.useState({
      total_files: 0,
      uploaded_files: 0,
      current_file_progress: 0
    });


  const [status, setStatus] = React.useState({
    filename: null, 
    status: null}
    );
    const [statusa, setStatusa] = React.useState({
      "total_files": null,
    "processed_files": null}
      );
   
    const [files, setFiles] = React.useState([]);
    const updateFiles = async (incommingFiles) => {
      setLoading(true);
        setFiles(incommingFiles);
        console.log(incommingFiles);
        const formData = new FormData();
        const pdffiles = incommingFiles.map(({ file }) => file);
        console.log(pdffiles);
        for (var i = 0; i < pdffiles.length; i++){
          formData.append('files',pdffiles[i])
        }
        const response= await fetch('https://api.resoclaim.com/filesmulti',{
          method:'POST',
          body:formData
        })
        const abc = await response.json();
    };
    const bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "gray.800"
      );
    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            <Dropzone onChange={updateFiles} value={files}>
        {files.map((file) => (
            <FileMosaic {...file} preview />
        ))}

        </Dropzone>
        <br></br>
        {loading ? ( // Show spinner if loading
        <Spinner size="lg" color="blue.500" />
      ) : (
        <Progress mt={4} value={progressData.current_file_progress} />
      )}<br></br>
            {!statusa.total_files&&<b>Uploaded Files: {progressData.uploaded_files} out of {progressData.total_files}</b>}
            {statusa.total_files&&<b>Processed Files: {statusa.processed_files} out of {statusa.total_files}</b>}
            {status.filename && (
                <div>
                    <p>{status.filename}</p>
                    <p>{status.status}</p>
                </div>
            )}
            <Flex>
                <Button bg={bgButton} color='white' marginTop={5} fontSize='xs' variant='no-hover'>
                    Proceed
                </Button>
            </Flex>
        </Flex>


    );
  }
  
// Chakra imports
import { Flex, SimpleGrid, Box, Select } from "@chakra-ui/react";
import React from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import { tablesTableData, MedicalBillTableData} from "variables/general";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import testpdf from "assets/FortisGur.pdf";
import fortispdf from "assets/fortisGurgaon.pdf";
import PDFDropzone from "components/PDFDropzone/PDFDropzone";
import { Document, Page } from 'react-pdf';
import axios from 'axios';

function Tables() {
  // const [progressData, setProgressData] = React.useState({
  //   total_files: 0,
  //   uploaded_files: 0,
  //   current_file_progress: 0
  // });
  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     try {
  //       const response = await fetch('http://localhost:8000/webhook', {
  //       method: 'POST',
  //     });
  //     const responseData = await response.json(); 
  //     setProgressData(responseData);
  //     } catch (error) {
  //       console.error("Error fetching progress data:", error);
  //     }
  //   }, 1000);
  //   return () => clearInterval(interval); 
  // }, []); 
  const [file, setFile] = React.useState([]);
  const [pdfname, setPdfname] = React.useState("test.pdf");
  const [medicalbilldata, setMedicalbilldata] = React.useState([]);
  const [billdiffdata, setBilldiffdata] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const updateFile = async (incommingFile) => {
    setFile(incommingFile);
    const formData = new FormData();
    formData.append('file', incommingFile[0].file);
    const response = await fetch('https://api.resoclaim.com/upload-pdf', {
      method: 'POST',
      body: formData
    })
    const pdfdata = await response.json();
    if (response.ok) {
      console.log('File uploaded successfully!');
      setPdfname(pdfdata["filename"])
      setMedicalbilldata(pdfdata["csvdata"])
      setBilldiffdata(pdfdata["diffdata"])
      console.log(pdfdata)
      console.log(pdfdata["csvdata"])
      console.log(pdfdata["diffdata"])
    } else {
      console.error('File upload failed:', response.status, response.statusText);
      // Handle failure as needed
    }
    
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const filterByCategory = (data) => {
    if (!selectedCategory) {
      return data;
    }
    return data.filter(item => item.category === selectedCategory);
  };
  
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* <Dropzone onChange={updateFiles} value={files}>
      {files.map((file) => (
        <FileMosaic {...file} preview />
      ))}
    </Dropzone> */}
      {/* <PDFDropzone /> */}
      <SimpleGrid columns={1} spacing={5}>
        {file.length!==0 ? <iframe src={`https://api.resoclaim.com/files/${pdfname}`} width="100%" height='700px' /> : 
        <Dropzone onChange={e => updateFile(e)} value={file} >
          {file.map((file1) => (
            <FileMosaic {...file1} preview />
          ))}
        </Dropzone>}

      </SimpleGrid>
      <SimpleGrid columns={2} spacing={5}>
      <iframe src={testpdf} width="100%" height='800px' type="application/pdf"/>
      <iframe src={fortispdf} width="100%" height='800px' type="application/pdf"/>
      </SimpleGrid>
      {/* <Projects
        title={"Medical Bill"}
        captions={["Particulars", "HSN/SAC", "Date", "Quantity", "Gross Amount", "Contract Discount", "Net Gross Amount"]}
        data={MedicalBillTableData}
      /> */}
      {/* <Projects
        title={"Medical Bill"}
        captions={["Particulars", "HSN/SAC", "Date", "Quantity", "Gross Amount", "Contract Discount", "Net Gross Amount"]}
        data={medicalbilldata}
      /> */}
      <div>
      {/* <p>Total Files: {progressData.total_files}</p>
      <p>Uploaded Files: {progressData.uploaded_files}</p>
      <p>Current File Progress: {progressData.current_file_progress}</p> */}
    </div>
      <div><br></br>
        <label htmlFor="category"><b>Filter by Category:</b><br></br></label>
        <br></br>
        <Select id="category" value={selectedCategory} onChange={handleCategoryChange} >
          <option value="">All Categories</option>
          <option value="CONSULTATION">Consultation</option>
          <option value="INVESTIGATION">Investigation</option>
          <option value="CONSUMABLE">Consumable</option>
          <option value="PHYSIOTHERAPY">Physiotherapy</option>
          <option value="EQUIPMENT">Equipment</option>
          <option value="OTHER PROCEDURE">Other Procedure</option>
          <option value="BLOOD COMPONENT">Blood Component</option>
          <option value="DRUG">Drug</option>
          <option value="ROOM RENT">Room Rent</option>
          <option value="SURGERY">Surgery</option>
          <option value="BED SIDE PROCEDURE">Bed Side Procedure</option>
          <option value="PACKAGE">Package</option>
          <option value="IMPLANT">Implant</option>
          
        </Select>   
        <br></br> 
      </div>
      <Authors
        title={"Medical Bill according to contracts"}
        captions={["Particulars", "Requested Amount", "Agreed SOC Tariff","score","category","difference amount","discount show","Remarks","New Match","Final Price"]}
        data={filterByCategory(billdiffdata)}
      />
    </Flex>
  );
}

export default Tables;
